import '../assets/styles/global.css'
import Header from '../components/Header';
import About from '../components/About';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import LightCursor from '../components/LightCursor';
import ParticleContainer from '../components/ParticlesContainer';

function Home() {
  return (
    <div>
      <ParticleContainer/>
      <div id="page-content">
        <Header/>
        <main>
            <About/>
            <Skills/>
            <Projects/>
        </main>
      </div>
      <LightCursor/>
    </div>
  );
}

export default Home;