import test from '../assets/images/projects/test.png';
import React, { useState } from 'react';

function Project({ name, tag, color, description}) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const linkStyle = {
        transition: '0.2s', color: isHovered ? color : 'white'
    };

    return (
        <a href="http://localhost:3000" class="project" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div class="img-container"><img src={test} alt="test"/></div>
            <div class="project-content">
                <div class="project-title">
                    <h3 class="project-name" style={linkStyle}>{name}</h3>
                    <p class="tag" style={{ backgroundColor: color }}>{tag}</p>
                </div>
                <p class="project-description">{description}</p>
            </div>
        </a>

    );
}

export default Project;