import React, { useState, useEffect } from 'react';

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

function Particle() {
  const [posX, setPosX] = useState(0);
  const [size, setSize] = useState(0);

  useEffect(() => {
    setPosX(getRandomNumber(-50, window.innerWidth));
    setSize(getRandomNumber(5, 15));
  }, []);

  return (
    <div>
      <div className="particle" style={{
        width: size, 
        height: size, 
        left: posX, 
        animation: `moveParticle ${getRandomNumber(10,15)}s linear`
      }}></div>
    </div>
  );
}

export default Particle;