import React, { useState, useEffect } from 'react';
import Particle from './Particle';

function ParticleContainer(){
    const [particles, setParticles] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setParticles(prevParticles => {
            if (prevParticles.length < 30) {
              return [...prevParticles, <Particle key={Date.now()} />];
            } else {
              return [...prevParticles.slice(1), <Particle key={Date.now()} />];
            }
          });
        }, 300);
        return () => clearInterval(interval);
      }, []);

    return (
        <div>
            {particles.map(particle => particle)}
        </div>
    );
}

export default ParticleContainer;