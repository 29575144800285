import '../assets/styles/About.css'

function About() {

    return (
        <div id="about">
            <h3 class="sectionTitle">A PROPOS</h3>
            <p>Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit. Nulla facilisi. Integer sed 
            diam eros. Phasellus euismod consectetur 
            ex, nec dapibus risus consectetur id. 
            </p>
            <p>Ut id est at sapien aliquet rhoncus nec et 
            tortor. Pellentesque habitant morbi tristique 
            senectus et netus et malesuada fames ac 
            turpis egestas. Integer nec ipsum a lectus 
            hendrerit eleifend. Quisque et mauris sit amet 
            urna consectetur mattis id sed lorem. </p>
        </div>
    );
}

export default About;