import React, { useState, useEffect } from 'react';
import '../assets/styles/Header.css';
import twitter from '../assets/images/twitter.png';
import youtube from '../assets/images/youtube.png';
import github from '../assets/images/github.png';

function Header() {
    const [activeSection, setActiveSection] = useState('about');

    useEffect(() => {
        const handleScroll = () => {
            const aboutDiv = document.getElementById('about');
            const skillsDiv = document.getElementById('skills');
            const projectsDiv = document.getElementById('projects');

            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;

            if (aboutDiv && aboutDiv.offsetTop < scrollPosition + windowHeight && aboutDiv.offsetTop + aboutDiv.offsetHeight > scrollPosition) {
                setActiveSection('about');
            }
            else if (skillsDiv && skillsDiv.offsetTop < scrollPosition + windowHeight && skillsDiv.offsetTop + skillsDiv.offsetHeight > scrollPosition) {
                setActiveSection('skills');
            }
            else if (projectsDiv && projectsDiv.offsetTop < scrollPosition + windowHeight && projectsDiv.offsetTop + projectsDiv.offsetHeight > scrollPosition) {
                setActiveSection('projects');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const links = document.querySelectorAll('nav a[href^="#"]');

        const handleClick = (e) => {
            e.preventDefault();

            const targetId = e.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        };

        links.forEach(link => {
            link.addEventListener('click', handleClick);
        });

        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleClick);
            });
        };
    }, []);

    return (
        <div>
            <header>
                <p id="myName">Je m'appelle,</p>
                <h1 id="fullName">Alexandre L.</h1>
                <h2 id="jobTitle">Développeur Full-Stack</h2>
                <p id="description">Chaque projet mérite d'être concrétisé, ne laissez pas passer cette opportunité de créer quelque chose d'exceptionnel.</p>
            
                <nav>
                    <ul id="navbar">
                        <li><a className={activeSection === 'about' ? 'nav-item active' : 'nav-item'} href="#about">A PROPOS</a></li>
                        <li><a className={activeSection === 'skills' ? 'nav-item active' : 'nav-item'} href="#skills">COMPÉTENCES</a></li>
                        <li><a className={activeSection === 'projects' ? 'nav-item active' : 'nav-item'} href="#projects">PROJETS</a></li>
                    </ul>
                </nav>

                <div id="socialLinks">
                    <a href="https://twitter.com/Alex00703_/"><img src={twitter} alt="x" /></a>
                    <a href="https://www.youtube.com/"><img src={youtube} alt="youtube" /></a>
                    <a href="https://github.com/Alex00703/"><img src={github} alt="github" /></a>
                </div>
            </header>
        </div>
    );
}

export default Header;